<template>
  <div>
    <div
      :class="scroll ? 'tncOverflow overflow-y-auto' : ''"
    >
      <h3>Terms & Conditions</h3>
      <h3>To Cover</h3>
      <p>
        Household Goods/Personal Effects and Private Passenger Carrying Automobiles, Privately Owned Motorcycles
        and Privately Owned boats, not exceeding seventeen feet in length as limited or as excluding in this
        Certificate of Insurance while in the course of transportation
      </p>

      <h3>Transit Insurance Coverage</h3>
      <p>
        Except while on deck of ocean vessel subject to on-deck bill of lading: <br>
        Against all risks of physical loss or damage from any external cause,irrespective of percentage,
        but excluding those risks excepted by the Free of Capture and Seizure and Strikes, Riots,
        and Civil Commotions warranties, unless otherwise specifically noted hereon.
      </p>
      <p>
        While on deck of ocean vessel subject to an on-deck bill of lading: <br>
        Warranted free of particular average unless caused by the stranding, sinking, burning or collision of the vessel;
        but to pay the insured value of any merchandise or goods jettisoned or washed overboard, irrespective of percentage.
      </p>
      <p>
        This insurance is subject to the American Institute Cargo Clauses current on date of attachment of risk hereunder.
        Note SR&CC War Risk Insurance is included.
      </p>
      <h3>CONDITIONS OF COVERAGE</h3>
      <ol type="A">
        <li>
          <b>100% Coinsurance Clause: </b>
          The insured shall declare insurance on the entire shipment to the extent of the full value at the time of
          shipment and failing to do so, the insured shall, to the extent of such deficit; bear their proportion of
          any loss.  Furthermore, in every event of loss or damage, the insurance shall not attach or cover for more
          than the amount specified opposite each category of goods listed in this certificate or as scheduled and
          filed with this certificate.
        </li>
        <li>
          <b>Pairs & Sets Clause: </b>
          Where any insurance items consists of articles in a pair or set, this certificate shall not pay more than
          the value of any particular part or parts, which may be lost or damaged, without reference to any special
          value which such article or articles may have as part of such pair or set, nor more than the proportionate
          part of the insured value of the pair or set.
        </li>
        <li>
          <b>Valuation Clause: </b>
          The household goods and personal effects insured must be valued either:
          <ol type="1">
            <li>
              At the replacement value at destination as supported by a complete valued inventory. Items of dissimilar
              value are to be individually insured.  Items grouped together will have a maximum recovery of the aggregate
              value divided by the total number of items listed.
            </li>
            <li>
              At the replacement value of the entire shipment at destination as supported by a declared value in no
              instance less than US$12 times the net weight of the shipment in pounds plus the value of all items of
              unusual or unique value.  Further the insured must provide a valued listing of all items valued over
              US$1,500 per item, set or entire contents of a box; otherwise reimbursement will be limited to that amount
            </li>
            <li>
              Automobiles, motorcycles and boats must be valued at the cost to replace the item at destination with
              another of the same year, make and model
            </li>
          </ol>
        </li>
        <li>
          <b>Deductible Clause: </b>
          Each claim shall be adjusted separately and from the amount of each such adjusted claim or applicable limit
          of liability whichever is less, the deductible amount as shown on this certificate shall be deducted.
          Shipments in storage that are extended beyond the combined 180 day origin/destination SIT coverage must be
          approved by Unirisc,, Inc. Shipments in USA storage are subject to the following deductibles for earthquake,
          wind and flood: US$5,000DA – losses up to $100,000, $7,500 – losses up to $200,000, $12,500 losses up to
          $500,000(MAY ONLY APPLY TO CERTAIN MOVING COMPANIES)
        </li>
        <li>
          <b>Prima Facie Evidence Clause: </b>
          The origin and/or destination shipping inventory as prepared by the mover shall be Prima Facie evidence
          of delivery of the shipment in good order with the except of any written notations made on such inventory
          by the Insured at the time of delivery, noting missing and/or damaged items
        </li>
        <li>
          <b>Repair or Replacement Clause: </b>
          <b>DO NOT DISPOSE OF OR DISCARD ANY ITEM WITHOUT WRITTEN AUTHORIZATION</b>Underwriters retain the right
          to inspect any item prior to its repair or disposal.  Underwriters shall be entitled, at their sole option,
          to repair or replace with like kind and quality , any article lost or damaged (whether whole or in part) or
          to pay cash therefore not exceeding, in any event, the amount of the insured item.  No betterment allowable.
        </li>
        <li>
          <b>Salvage Clause: </b>
          Where replacement or total loss payment of a damaged article(s) is made by Underwriters, they, at their sole
          option, have the right to salvage the damaged article (s).
        </li>
        <li>
          <b>Claims Notification: </b>
          In the event of loss, damage or non-delivery which may give rise to a claim under this certificate, immediate
          notice must be given, in writing, to Unirisc, Inc.at the address shown on this certificate.  Failure to give
          such notice within 45 days after delivery of the shipment will void coverage under this certificate.
        </li>
        <li>
          <b>Misrepresentation and Fraud: </b>
          This entire certificate shall be void if, whether before or after a loss, the insured has concealed or
          misrepresented any material fact or circumstances concerning this insurance or the subject thereof, or the
          interest of the insured therein, or in case of any fraud or false swearing by the insured relating thereto.
        </li>
        <li>
          <b>Suit Against Company: </b>
          No suit, action or proceeding against this Company for recovery of any claim shall be sustainable unless
          commenced within one year from the date of the happening out of which the claim arises, provided that if
          such limitation is invalid by the laws of the state in which this certificate is issued then such suit,
          action or proceeding should be barred unless commenced within the shortest limit of time permitted by the
          laws of such state.
        </li>
        <li>
          <b>Transit Limits: </b>
          <ol type="1">
            <li>
              <b>Household Goods: </b>
              Coverage is to attach from date the Moving Company accepts property at origin residence which is the
              date shown on the Moving Company’s origin shipping inventory and is continuous during the normal course
              of transit until the Moving Company delivers the property at destination residence provided that all
              other terms and conditions of this are met.  The origin and destination referred to in this clause means
              the FROM and TO, as appropriate locations specified on this certificate.
            </li>
            <li>
              <b>Automobiles, Motorcycles and Boats: </b>
              Coverage is to attached from the date that the automobile, motorcycle or boat is placed in the custody
              of the Moving Company or Steamship Company and continues until the automobile, motorcycle and boat is
              delivered to the destination specified on this certificate, provided it is not operated on public or
              private roads under its own power.   Further, coverage does not apply for any period exceeding 72 hours
              at destination ocean port, should the ocean port be the final destination. Excluding Recreational Vehicles.
            </li>
            <li>
              <b>Storage In Transit Coverage Extensions: </b>
              Coverage is intended to apply within the country of origin or final destination for a period of 180 day
              or as otherwise agreed provided that the property is stored in an enclosed, protect commercial Moving
              Company’s household goods warehouse under the care, custody and control of the Thru-Bill of Lading Moving
              Company (or their designated agent).  Mini-storage and/or self-storage facilities are excluded.  Storage
              may be extended for additional periods of time storage subject to prior special written notice and payment
              of additional premium to Unirisc,.
            </li>
          </ol>
        </li>
        <li>
          <b>Other Insurance: </b>
          This insurance does not cover to the extent of any other insurance, whether prior of subsequent hereto in date
          and by whomsoever effected, directly or indirectly covering the same property, and the Company shall be liable
          for loss or damage only for the excess value beyond the amount due from such other insurance.
        </li>
        <li>
          <b>Subrogation Clause: </b>
          The Company shall be subrogated to the extent of their payment for losses insured hereunder and to the insured’s
          rights to recovery against any person or organization; excepting the origin and destination freight forwarders who
          performed pickup, packing, delivery and unpacking services in connection with the movement of the shipment other than
          in the event of gross negligence.  All provisions of this clause notwithstanding, it is hereby warranted that the
          Insured shall take all necessary actions to protect the Company’s rights of subrogation against culpable parties.
          Failure to take such action, causing prejudice to the Company’s rights of subrogation, may result in denial or
          reduction of the claim.
        </li>
        <li>
          <b>Burden/Duty of Insured: </b>
          The burden of proof is upon the Insured to establish that loss and/or damage was incurred while under the ambit
          of this certificate’s coverage.  It is the duty of the Insured and their agents, in all cases, to take such
          measures as may be reasonable for the purpose of averting or minimizing a loss and to ensure that all rights against
          carriers, bailees, or other third parties are properly preserved and exercised.  Failure by the Insured to fulfill
          these obligations could preclude recovery for any claimed loss and/or damage.
        </li>
        <li>
          <b>High Value Articles: </b>
          Any item with an individual value US$2,500 or more is defined as a “High Value Article”.  Items in this category
          must be specifically described, declared and valued in writing before the date property is picked up from the
          origin specified in this certificate.
        </li>
        <li>
          <b>Premium Payment: </b>
          Where the named insured herein has not paid premium directly to Unirisc,, any party receiving premium from
          the herein named insured is construed as the Insured’s agent for payment of said premium to Unirisc,, and
          failure of Unirisc, Inc.to receive such premium will void any coverage under this certificate.
        </li>
        <li>
          <b>Surveys: </b>
          Survey Inspection Fees are payable by this company only with prior consent by Unirisc.
        </li>
        <li>
          <b>Abandonment: </b>
          There cannot be any abandonment of any insured property to the Underwriters or anyone else.
        </li>
        <li>
          <b>Assignment of Certificate: </b>
          This certificate shall be void if assigned or transferred without the written consent of this Company.
        </li>
        <li>
          <b>Total Loss/FPA: </b>
          Warranted free of particular average unless caused by stranding, sinking, burning, and/or collision of the vessel;
          but to pay the insured value of any merchandise and/or goods jettisoned and/or washed overboard, irrespective of
          percentage No coverage for partial loss. Including theft and or non delivery of the entire shipment
        </li>
      </ol>
      <br>
      <h3>
        This insurance is subject to the American Institute Cargo Clauses current on date of attachment of risk hereunder.
        Note SR&CC War Risk Insurance is included.
      </h3>
      <p><b>Exclusions</b></p>
      <p>This insurance does NOT cover:</p>
      <div>
        Damage including but not limited to mold, mildew, rust, and warping. Damages caused by climactic conditions.
        Spoilage or change in food or beverage of any kind.
      </div>
      <div>
        Loss or damage cause by normal wear and tear, mechanical or electrical derangement, wrinkling of clothing,
        worn shoes, purses, hand bags accessories and the like, discoloration of leather, infestation of vermin, moths,
        insects of any type or inherent vice. Loss or damage attributable to fumigation or contamination of the shipment
        from any cause.
      </div>
      <div>
        Jewelry, furs, cash, currency, bank notes, stocks, bonds, stamp and/or coin collections or any negotiable document.
        Collections and/or collectibles defined as but not limited to baseball cards, sports memorabilia, collectible toys,
        etc. are only insured if specifically declared, separately valued and appraised prior to shipment.  Limited to
        maximum of 10% of the shipment value. Artwork and Antiques individually valued over $10,000 USD must be appraised
        by a professional appraisal firm or have a commercial invoice prior to shipping and must be approved by Unirisc.
      </div>
      <div>
        Missing and/or damaged items from within containers which were not packed by the current Household Goods Moving Company,
        unless loss/damage is caused by a direct result of fire, sinking, overturn, collision or theft of the transporting conveyance.
      </div>
      <div>
        Loss and/or damage of any type to an automobile or motorcycle while being driven under its own power except
        for the purpose of loading and unloading.
      </div>
      <div>
        Non-factory installed accessories and/or removable items on automobiles, motorcycles and boats unless specifically
        and individually declared and valued for insurance.   Tools, batteries, extra tires, antennas, air bags and/or
        personal property shipped in automobiles, motorcycles and boats are not insured.
      </div>
      <div>
        Scratching, denting, chipping or marring of automobiles, motorcycles and boats over 5 years old. Scratching,
        denting, chipping or marring of automobiles, motorcycles and boats under 5 years old unless the shipper and
        the owner both agree to sign a “Condition Inspection Report” or similar document portraying the condition at
        origin and again at destination, noting all defects, if any. This policy excludes any coverage for recreational
        vehicles.
      </div>
      <div>
        Calibration and/or tuning of any item, machine, device or equipment.
      </div>
      <div>
        Acts of government officials and customs authorities, including confiscation. Consequential losses due to delay
        are not covered.
      </div>
      <div>
        Data contained on hard disks, diskettes, cassettes, video tapes, CD’s, etc.  Company’s liability is limited to
        the cost of hardware only, except as may be excluded elsewhere in this certificate.
      </div>
      <div>
        Depreciation in market or appraised value of any item.  Underwriter’s liability hereon is governed by the
        “Repair and Replacement Clause” found in this certificate.
      </div>
      <div>
        Loss or damage of personal and/or professional papers/documents of any kind, including but not limited to
        dissertations, tax returns, medical and employment records; items have that have no market value (such as but
        not limited to photographs, family albums and pictures, sentimental items, newspaper clippings, etc.).
      </div>
      <div>
        Non delivery of a shipping package if the delivery receipt shows that all packages were delivered to the final
        destination.
      </div>
      <div>
        Items not listed in the inventory prepared at origin. Items not shipped are not insured.
      </div>
      <br>
      <h3>OPTIONAL COVERAGE TERMS & CONDITIONS</h3>
      <p>
        <b>Mold & Mildew - </b>
        To include loss or damage in respect to mold & mildew to the interest insured, subject to goods being professionally packed.
      </p>
      <p>
        <b>Electrical & Mechanical Derangement (Excluding Autos) - </b>
        To include loss or damage to the interest insured which is caused by electronic and/or
        electrical and/or mechanical derangement, provided the interest insured is less than 6
        years old. Subject to the goods being professionally packed.
      </p>
      <p>
        <b>Pairs & Sets Coverage – </b>
        In the event of loss or damage recoverable to any item or items forming part of a pair
        or set, the indemnity afforded by this policy shall be limited to the reasonable and
        fair reduction in value of the pair or set by reason of the loss or damage to the affected
        items. All the articles constituting the pair or set shall, at Underwriters option become
        their property in the event that the Underwriters agree to pay the total loss of the pair or entire set.
      </p>
      <p>
        <v-checkbox
          ref="tncagree"
          v-model="fd.agree"
          :rules="[rules.required]"
          label="I agree to your terms and conditions"
        />
      </p>
    </div>
    <v-row>
      <v-col>
        <v-text-field
          ref="name"
          v-model="fd.name"
          label="Name"
          :rules="[rules.required]"
          @input="changeField('name', fd.name)"
        />
        <v-menu
          v-model="datePicker"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          min-width="290px"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              ref="date"
              v-model="fd.date"
              label="Date"
              :rules="[rules.required]"
              readonly
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="fd.date"
            class="ma-0"
            @input="datePicker = false;changeField('date', fd.date)"
          />
        </v-menu>
      </v-col>
      <v-col>
        <base-signature-pad
          v-if="showPad || format==='pdf' || readonly"
          ref="pad"
          :value="fd.signature"
          :readonly="readonly"
          :rules="[rules.required]"
          :buttons="format!=='pdf'"
          @save="changeField('signature', $event)"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    props: {
      inputs: {
        type: [Object, Array, String],
        default: () => ({
          agree: '',
          signature: {},
          name: '',
          date: new Date().toISOString().substr(0, 10),
        }),
      },
      format: {
        type: String,
        default: '',
      },
      isOpen: {
        type: Boolean,
        default: false,
      },
      readonly: {
        type: Boolean,
        default: false,
      },
      scroll: {
        type: Boolean,
        default: true,
      },
    },
    data () {
      return {
        fd: this.inputs,
        showPad: false,
        datePicker: '',
        rules: {
          required: v => !!v || 'Required',
        },
      }
    },
    watch: {
      isOpen (val) {
        if (val) this.openPad()
      },
      inputs: {
        deep: true,
        handler (v) { this.fd = v },
      },
    },
    methods: {
      changeField (name, val) {
        this.$set(this.fd, name, val)
        this.$emit('updateField', this.fd, 'tnc')
      },
      validate () {
        return this.inpValidate(this.$refs)
      },
      openPad () {
        this.showPad = true
      },
      async save () {
        await this.$refs.pad.save()
      },
    },
  }
</script>

<style scoped>
  .tncOverflow{
    max-height: 300px;
    background: #cccccc4d;
  }
  .tncOverflow::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.tncOverflow::-webkit-scrollbar-track {
  background: #c2c2c2
}

/* Handle */
.tncOverflow::-webkit-scrollbar-thumb {
  background: #03aa28;
  border-radius: 5px;
}

/* Handle on hover */
.tncOverflow::-webkit-scrollbar-thumb:hover {
  background: #367644;
}
</style>
